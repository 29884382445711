
.ho_login_form {
  max-width: 360px;
  /* width: 300px; */
}

.ho_login_button {
  width: 100%;
  
}

.ho_table_cell_1 {
  background-color: #f0f5ff;
}


.ho_doc_container{
  box-sizing: border-box;
  width: 1000px;
  max-width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 30px 20px;
  overflow: hidden;
  background-color: #fff;
  border-right: solid 1px #eee;
  border-left: solid 1px #eee;
}
.ho_doc_container img,
.ho_doc_container audio,
.ho_doc_container video{
  max-width: 100%;
  height: auto;
}
.ho_doc_container p{
  white-space: pre-wrap;
  min-height: 1em;
}
.ho_doc_container pre{
  padding: 15px;
  background-color: #f1f1f1;
  border-radius: 5px;
}
.ho_doc_container blockquote{
  margin: 0;
  padding: 15px;
  background-color: #f1f1f1;
  border-left: 3px solid #d1d1d1;
}

.ho_margin_bottom_detault {
  margin-bottom: 16px;
}


